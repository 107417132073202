import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model31',
  templateUrl: './model31.component.html',
  styleUrls:  ['./model31.component.css']
})
export class Model31Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
