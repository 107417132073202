export class AnimationService
{
    footerAnim1:object={
        reset:true,
        duration:800,
        origin:'bottom',
        distance:'20px',
        easing:'ease-in-out',
      };
      footerAnim2:object={
        reset:true,
        delay:600,
        duration:800,
        origin:'bottom',
        distance:'20px',
        easing:'ease-in-out',
      };
     footerAnim3:object={
        reset:true,
        duration:600,
        origin:'left',
        distance:'200px',
        easing:'linear',
      };
      footerAnim4:object={
        reset:true,
        duration:600,
        origin:'right',
        distance:'50px',
        easing:'linear',
      };
 
      whyjoin:object={
        reset:true,
        duration:800,
        origin:'bottom',
        distance:'20px',
        easing:'ease-in-out',
      };
     
      lefttop:object={
        reset:true,
        duration:400,
        origin:'left',
        distance:'200px',
        easing:'linear',
      };
      righttop:object={
        reset:true,
        delay:400,
        duration:400,
        origin:'right',
        distance:'150px',
        easing:'linear',
      };
      leftbottom:object={
        reset:true,
        delay:800,
        duration:400,
        origin:'left',
        distance:'200px',
        easing:'linear',
      };
      rightbottom:object={
        reset:true,
        delay:1200,
        duration:400,
        origin:'right',
        distance:'150px',
        easing:'linear',
      };
}