import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upmodal2',
  templateUrl: './upmodal2.component.html',
  styleUrls: ['./upmodal2.component.css']
})
export class Upmodal2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
