import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-phase4',
  templateUrl: './phase4.component.html',
  styleUrls: ['./phase4.component.css']
})
export class Phase4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
