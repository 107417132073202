import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-phase3',
  templateUrl: './phase3.component.html',
  styleUrls: ['./phase3.component.css']
})
export class Phase3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
