import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model9',
  templateUrl: './model9.component.html',
  styleUrls: ['./model9.component.css']
})
export class Model9Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
