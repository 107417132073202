import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edu-training',
  templateUrl: './edu-training.component.html',
  styleUrls: ['./edu-training.component.css']
})
export class EduTrainingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
