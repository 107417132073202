import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-upmodal",
  templateUrl: "./upmodal.component.html",
  styleUrls: ["./upmodal.component.css"]
})
export class UpmodalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
