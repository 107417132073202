import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model5',
  templateUrl: './model5.component.html',
  styleUrls: ['./model5.component.css']
})
export class Model5Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
