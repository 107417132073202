import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model25',
  templateUrl: './model25.component.html',
  styleUrls: ['./model25.component.css']
})
export class Model25Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
