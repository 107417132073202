import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recent-events',
  templateUrl: './recent-events.component.html',
  styleUrls: ['./recent-events.component.css']
})
export class RecentEventsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
