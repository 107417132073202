import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model11',
  templateUrl: './model11.component.html',
  styleUrls: ['./model11.component.css']
})
export class Model11Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
