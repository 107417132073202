import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model7',
  templateUrl: './model7.component.html',
  styleUrls: ['./model7.component.css']
})
export class Model7Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
