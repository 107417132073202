import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model15',
  templateUrl: './model15.component.html',
  styleUrls: ['./model15.component.css']
})
export class Model15Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
