import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-model6",
  templateUrl: "./model6.component.html",
  styleUrls: ["./model6.component.css"]
})
export class Model6Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
