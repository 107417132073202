import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-model0",
  templateUrl: "./model0.component.html",
  styleUrls: ["./model0.component.css"]
})
export class Model0Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
