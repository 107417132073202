import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model22',
  templateUrl: './model22.component.html',
  styleUrls: ['./model22.component.css']
})
export class Model22Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
