import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model18',
  templateUrl: './model18.component.html',
  styleUrls: ['./model18.component.css']
})
export class Model18Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
